if ('define' in window) {
define("discourse/theme-11/discourse/api-initializers/init-search-banner", ["exports", "discourse/lib/api", "../components/search-banner"], function (_exports, _api, _searchBanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(11);
  const themePrefix = key => `theme_translations.11.${key}`;
  var _default = _exports.default = (0, _api.apiInitializer)("1.14.0", api => {
    api.renderInOutlet(settings.plugin_outlet === "above-main-container" ? "above-main-container" : "below-site-header", _searchBanner.default);
    api.forceDropdownForMenuPanels("search-menu-panel");
  });
});
}
